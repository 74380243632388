<template>
  <div class="vr">
    <div class="header">
      <!-- <div class="title-top">酷学VR介绍</div>

      <div class="title-bottom">
        基于自身语言方面的优势特点，联合国内头部企业发挥各自优势，集合新型静音仓空间、结合虚拟仿真、人工智能、大数据分析技术设计研发了一款创新学习产品
        ——酷学沉浸时VR学习平台。
      </div> -->
    </div>

    <div class="content">
      <div class="future">
        <h3 class="h3">未来语言学习中心建设</h3>
        <div class="room-content">
          <div class="room" v-for="(item, index) in futureData" :key="index">
            <template v-if="!item.isRight">
              <img :src="item.img" alt="" />
              <div style="margin-left: 20px">
                <h3>{{ item.name }}</h3>
                <p>{{ item.desc }}</p>
              </div>
            </template>
            <template v-else>
              <div style="margin-right: 20px; flex: 1">
                <h3>{{ item.name }}</h3>
                <p>{{ item.desc }}</p>
              </div>
              <img :src="item.img" alt="" />
            </template>
          </div>
        </div>
      </div>
      <div class="future bgi">
        <h3 class="h3">可支持的静音仓类型</h3>
        <div class="room rooms">
          <div
            class="room-left"
            v-for="(item, index) in vrData"
            :key="index"
            @mouseover="showDetails(index)"
            @mouseout="hideDetails(index)"
          >
            <div class="item">
              <img :src="item.img" alt="" />
              <div v-show="!item.showDetails" class="item-name">
                {{ item.name }}
              </div>
            </div>

            <div class="contents" :class="{ active: item.showDetails }">
              <h3>{{ item.name }}</h3>
              <div class="line">
                <h3>占地尺寸：</h3>
                <div>{{ item.size }}</div>
              </div>

              <div class="line">
                <h3>建议位置:</h3>
                <div>{{ item.position }}</div>
              </div>
              <div class="line">
                <h3>主要功能:</h3>
                <div>{{ item.function }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="future video">
        <h3 class="h3">VR 演示视频</h3>
        <div class="video-cotent">
          <div
            class="video-item"
            v-for="(item, index) in videoData"
            @click="govideo(item)"
            :key="index"
          >
            <img
              :src="item.img"
              alt=""
              style="width: 373px; height: 280px; border-radius: 20px"
            />
            <div class="bg">
              <img src="@/assets/img/vr/play.png" alt="" />
            </div>
            <div class="video-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      futureData: [
        {
          name: "可移动式物理空间（静音仓）",
          img: require("@/assets/img/vr/room6.png"),
          isRight: false,
          desc: "不需装修，简便快捷，灵活。解决噪声困扰，图书馆作为一个特殊的公共场所，安静的环境是图书馆最基本的条件和要求。噪声困扰不仅是图书馆品质问题，还不利于读者使用。因此，控制图书馆噪声， 为读者提供一个宁静的阅读空间是我们共同讨论的话题。",
        },
        {
          name: "VR数显头盔",
          isRight: true,
          img: require("@/assets/img/vr/look.png"),
          desc: "配套实现虚拟仿真学习系统，并具有强大的扩展功能，学习之余适当娱乐，解压、放松身心。",
        },
        {
          name: "沉浸式虚拟仿真学习系统",
          img: require("@/assets/img/vr/peoples.png"),
          isRight: false,
          desc: "本系统结合虚拟仿真技术为用户提供高度仿真的实训情境和真实的交互训练，用户可在系统内置的沉浸式情境中进行会话训练，内置情境包括商务职场、酒店餐饮、旅游导览、购物消费、医疗健康、邮件服务、金融结算七大类别等应用场景。可为用户营造 360°的沉浸式体验，让用户自主进行学习。本系统使用三维建模软件与渲染引擎构建的6自由度可漫游场景模型，VR 客户端可通过手柄控制在虚拟场景中漫游。",
        },
        {
          name: "预约系统",
          isRight: true,
          img: require("@/assets/img/vr/scan.png"),
          desc: "为合理有序使用，针对学习空间及VR设备开发了配套预约系统，简洁易操作。只需扫描静音舱上的二维码即可预约，技术人员可在后台操作。",
        },
      ],
      vrData: [
        {
          name: "数字阅读舱",
          img: require("@/assets/img/vr/room1.png"),
          size: "1 5 0 0 * 1 2 3 6 * 2 3 0 0 m m",
          position: "开放学习区周边",
          function: "符合人体工学的设计，独立专注的学习空间",
          showDetails: false,
        },
        {
          name: "双人辅导、交流舱",
          img: require("@/assets/img/vr/room2.png"),
          size: "1 5 0 0 * 1 2 3 6 * 2 3 0 0 m m",
          position: "开放学习区周边",
          function: "保证辅导内容私密性 ，同时交流声不影响他人",
          showDetails: false,
        },
        {
          name: "多人会议仓(4人)",
          img: require("@/assets/img/vr/room3.png"),
          size: "2 2 0 0 * 1 5 3 6 * 2 3 0 0 m m",
          position: "开放学习区周边",
          function:
            "线上线下会议，缓解会议室紧张，保证会议内容私密性，同时讨论声不影响他人",
          showDetails: false,
        },
        {
          name: "多人会议仓(8人)",
          img: require("@/assets/img/vr/room4.png"),
          size: "2 2 0 0 * 1 5 3 6 * 2 3 0 0 m m",
          position: "开放学习区周边",
          function:
            "线上线下会议，缓解会议室紧张，保证会议内容私密性，同时讨论声不影响他人",
          showDetails: false,
        },
      ],
      videoData: [
        {
          url: "https://www.aikoolearn.com/video-dev/profile/upload/2024/03/22/2513_20240322103502A001.mp4",
          name: "VR基本介绍",
          img: require("@/assets/img/vr/one.png"),
        },
        {
          url: "https://www.aikoolearn.com/video-dev/profile/upload/2024/03/22/2514_20240322103532A002.mp4",
          name: "雅思操作视频",
          img: require("@/assets/img/vr/two.png"),
        },
        {
          url: "https://www.aikoolearn.com/video-dev/profile/upload/2024/03/22/2515_20240322103707A003.mp4",
          name: "情景会话操作视频",
          img: require("@/assets/img/vr/three.png"),
        },
        {
          url: "https://www.aikoolearn.com/video-dev/profile/upload/2024/03/22/2516_20240322103734A004.mp4",
          name: "地球村操作视频",
          img: require("@/assets/img/vr/four.png"),
        },
        {
          url: "https://www.aikoolearn.com/video-dev/profile/upload/2024/03/22/2517_20240322103804A005.mp4",
          name: "五史教育操作视频",
          img: require("@/assets/img/vr/five.png"),
        },
      ],
    };
  },
  created() {},
  methods: {
    showDetails(index) {
      // 当鼠标悬停时，设置对应项的 showDetails 为 true
      this.vrData[index].showDetails = true;
    },
    hideDetails(index) {
      // 当鼠标移出时，设置对应项的 showDetails 为 false
      this.vrData[index].showDetails = false;
    },
    govideo(item) {
      window.open(item.url);
    },
  },
};
</script>
<style lang="less" scoped>
.vr {
  max-width: 1920px;
  margin: 0 auto;
  .header {
    max-width: 1920px;
    width: 100%;
    height: 700px;
    margin: 0 auto;
    background-image: url("../assets/img/vr/vr-bgi.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;

    .title-top {
      width: 488px;
      height: 60px;
      font-size: 52px;
      color: #fff;
      font-weight: 500;
      position: absolute;
      top: 241px;
      left: 360px;
    }
    .title-bottom {
      width: 488px;
      height: 60px;
      font-size: 20px;
      color: #fff;
      margin-top: 18px;
      position: absolute;
      top: 332px;
      left: 360px;
    }
  }

  .content {
    // width: 1920px;
    background-color: #fff;
    margin: 0 auto;
    padding: 60px 0;
    .future {
      h3 {
        font-size: 32px;
        text-align: center;
      }
    }
    .room {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 20px auto;
      width: 1200px;
      padding: 20px;
      height: 240px;
      background-color: #f5f7f7;
      box-sizing: border-box;
      h3 {
        font-size: 20px;
        text-align: left;
      }
      p {
        font-size: 16px;
        color: #808080;
        margin-top: 11px;
      }
    }
    .bgi {
      margin-top: 60px;
      background: linear-gradient(180deg, #ffffff 0%, #e4f2f0 100%);
      height: 607px;
    }
  }

  .item-name {
    position: absolute;
    width: 124px;
    line-height: 32px;
    background: #0fb09b;
    border-radius: 0px 12px 0px 12px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    bottom: 0;
  }

  .room-left {
    display: flex;
    align-items: center;
    .contents {
      display: none;
    }
    .active {
      width: 230px;
      flex: 1;
      /* 当 showDetails 为 true 时显示详情部分 */
      display: block;
    }
  }
  .item {
    position: relative;
    margin-left: 30px;
  }
  .rooms {
    height: 370px !important;
    background-color: #fff !important;
    border-radius: 20px;
    margin-top: 50px !important;
    padding: 0 !important;
  }

  .line {
    margin-top: 20px;
    h3 {
      font-size: 12px !important;
    }
    div {
      font-size: 12px !important;
    }
  }

  .video {
    background: linear-gradient(180deg, #e4f2f0 0%, #ffff 100%) !important;
    .video-cotent {
      width: 1290px;
      margin: 0 auto;
      margin-top: 14px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 49px;
      .video-item {
        margin-left: 40px;
        margin-top: 30px;
        position: relative;
        .bg {
          width: 373px;
          height: 280px;
          background-color: rgba(0, 0, 0, 0.4);
          position: absolute;
          top: 0;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          img {
            width: 54px;
            height: 54px;
          }
        }
        &:hover {
          .bg {
            opacity: 1;
          }
        }
      }
    }
    .video-name {
      margin-top: 16px;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .room-content {
    margin-top: 40px;
    padding-bottom: 60px;
  }
  .h3 {
    position: relative;
  }
  .h3::after {
    content: ""; /* 必须设置，表示伪元素的内容为空 */
    position: absolute; /* 相对于div定位 */
    bottom: -19px; /* 放置在div的底部 */

    left: 50%; /* 从div的左边开始，但向右偏移50% */
    transform: translateX(-50%); /* 向左移动自身宽度的50%，以实现居中 */
    width: 50px;
    height: 8px;
    background: #0fb09b;
    border-radius: 6px;
  }
}
</style>
